<template>
  <div class="site-content">
    <router-view />
  </div>
</template>

<script>
export default {
  
}
</script>